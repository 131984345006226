.divloginlogo{
    text-align: center;
    padding-top: 20px;

    
}
.loginlogo{
    width: 160px;
    height: 160px;
    border-radius: 80px;
}
.btn_site{
    background-color: #003067 !important;
    color: #fff;
}
.logoSidbar{
    width: 180px ;
    height: 200px !important;
}
.loaderform{
    padding-left: 40%;
}